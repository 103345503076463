import "./styles.css";

// @ts-ignore
import type {StatusJob} from "./server.ts";
import PartySocket from "partysocket";
import {Models, TestModelSteps} from "./gateway/config.js";
import {arrow, computePosition, flip, offset, shift} from '@floating-ui/dom';

declare const PARTYKIT_HOST: string;

let random_model_button : HTMLButtonElement;
let isRandomProgramOn : boolean = false

let specific_model_button : HTMLButtonElement;
let isSpecificProgramOn : boolean = false

let automatic_testing_model_button : HTMLButtonElement;

let model_dropdown : HTMLSelectElement;

const models_view = document.getElementById("models-view");
const buttonsSection = document.getElementById("buttons-view") as HTMLElement;
let admin_token_input : any = document.getElementById("admin-token-input") as HTMLInputElement;

const status_tooltip = document.getElementById("status-tooltip") as HTMLElement;
const status_tooltip_arrow = document.getElementById("status-tooltip-arrow") as HTMLElement;

let rawLocalStorage = new Map()
let localStorage = new Map()

let userToken = "client"

window.onload = function() {
  // Create the input box to log in
  admin_token_input = document.createElement('input')
  admin_token_input.type = "password"
  admin_token_input.id = "admin-token-input"
  admin_token_input.style.opacity = "0"
  admin_token_input.style.cursor = "default"
  admin_token_input.addEventListener('focus', () => {
    admin_token_input.style.opacity = "1"
    admin_token_input.style.cursor = "text"
  })
  admin_token_input.addEventListener('blur', () => {
    admin_token_input.style.opacity = "0"
    admin_token_input.style.cursor = "default"
  })
  // admin_token_input.placeholder = "Admin Code"
  admin_token_input.autocomplete = "off"
  admin_token_input.className = "bottom-right-UI"
  buttonsSection.appendChild(admin_token_input)

  admin_token_input.addEventListener('keypress', (event : any)  => {
    if(event.key === "Enter")
      tokenAuthentication(admin_token_input.value)
  })

  // Retrieve the storage from the server and create the view
  httpGetFromServer("models")
  createModelsView()
};

const connection = new PartySocket({
  host: PARTYKIT_HOST,
  room: "gateway-status",
    query: async () => ({
    token: "client"
  })
});



function sendSocketActionToServer(actionType : string, data : any){
  const action : Action = {
    //@ts-ignore
    type: actionType,
    data: data
  }
  connection.send(JSON.stringify(action))
}

connection.addEventListener("message", (event) => {

  const action = JSON.parse(event.data)

  // Update Data for one Model
  if(action.type === "updateModelData") {
    let temp = action.data
    rawLocalStorage.set(temp[0].name + getDateFromUTCString(temp[0].firstCallTime), temp)
    cleanRawStorage()
    updateRealTimeModelView(localStorage.get(temp[0].name))
  }

  // Update Data for all Models
  if(action.type === "updateStorageData") {
    rawLocalStorage = new Map(JSON.parse(action.data));
    cleanRawStorage()
    updateRealTimeView()
  }
});

function sendHTTPActionToServer(actionType : string, data : any){
  const action : Action = {
    //@ts-ignore
    type: actionType,
    data: data
  }

  // Send an action to the server using the user permissions token
  PartySocket.fetch(
      { host: PARTYKIT_HOST, room: 'gateway-status',
      },
      {
        headers: {
          'Authorization' : userToken
        },
        method: "POST",
        body: JSON.stringify(action)
      }
  ).then(response => {
  }).catch(error => {
    console.error('Error fetching data:', error);
  });
}

async function httpGetFromServer(path_ : string){
  PartySocket.fetch(
      { host: PARTYKIT_HOST, room: 'gateway-status', path: path_},
      {
        headers: {
          'Authorization' : userToken
        },
        method: "GET",
        mode: "cors"
      }
  ).then(response => {
    const path = new URL(response.url).pathname.split("/")

    // Receive the data from all models
    if(path[3] == "models" && !path[4])
    {
      response.json()
          .then(temp => {
            let parsedResponse = JSON.parse(temp)
            rawLocalStorage = new Map(parsedResponse);
            cleanRawStorage()
            updateRealTimeView()
          })
    }

    // Receive the data from a specific model
    if(path[3] == "models" && path[4])
    {
      response.json()
          .then(temp => {
            let parsedResponse = JSON.parse(temp)
            rawLocalStorage.set(parsedResponse[0].name + getDateFromUTCString(parsedResponse[0].firstCallTime), parsedResponse)
            cleanRawStorage()
            updateRealTimeModelView(localStorage.get(parsedResponse[0].name))
          })
    }
  }).catch(error => {
    console.error('Error fetching data:', error);
  });
}

async function tokenAuthentication(inputToken : string) {

  // The input is sent as the auth token to check permissions with the server
  PartySocket.fetch(
      { host: PARTYKIT_HOST, room: 'gateway-status', path: 'login' },
      {
        headers: {
          'Authorization' : inputToken
        },
        method: "GET",
        mode: "cors"
      }
  ).then(response => {
    response.json()
        .then(temp => {
          // If true, grant admin permissions
          let parsedResponse = JSON.parse(temp)
          if(parsedResponse) {
            userToken = inputToken
            showAdminUI()
          } else
            admin_token_input.value = ""
        })
  }).catch(error => {
    // Handle any errors here
    console.error('Error fetching data:', error);
  });
}



function updateRealTimeView(){

// Iterate through all Models and update their statuses
  localStorage.forEach(modelJobsList => {
    updateRealTimeModelView(modelJobsList)
  })
}

function updateRealTimeModelView(modelJobsList : StatusJob[]){
  const modelDiv = document.getElementById(modelJobsList[0].name)

  let last30GenTimes : string[] = []

  // Get all HTML rectangles to update them
  if(modelDiv){
    let squareDivs = modelDiv.querySelectorAll(".status-square")

    // Iterate & Update rectangle's colors depending on the status of the Jobs
    for(let i = 0; i < squareDivs.length; i++)
    {
      let squareColour = "lightgray"

      // To show only the latest Jobs in the list
      let listOffset = modelJobsList.length - squareDivs.length
      let index = listOffset > 0 ? i + listOffset : i

      if(modelJobsList[index])
      {
        let squareStatus = modelJobsList[index].status
        const localFirstCallTime = new Date(modelJobsList[index].firstCallTime)
        const localLastUpdateTime = new Date(modelJobsList[index].lastUpdateTime)

        switch (squareStatus) {
          case TestModelSteps.STARTING:
            squareColour = "#a5b4e7"
            break;
          case TestModelSteps.GENERATING:
            squareColour = "#7C91DF"
            break;
          case TestModelSteps.COMPLETED:
            squareColour = "#62d582"
            last30GenTimes.push(getTimeDifference(localFirstCallTime, localLastUpdateTime))
            break;
          case TestModelSteps.FAILED:
            squareColour = "#ec5964"
            last30GenTimes.push(getTimeDifference(localFirstCallTime, localLastUpdateTime))
            break;
          case TestModelSteps.TIMEOUT_START:
            squareColour = "#FEE391FF"
            break;
          case TestModelSteps.TIMEOUT_GENERATING:
            squareColour = "#FEE391FF"
            break;
          case TestModelSteps.NONE:
            squareColour = "lightgray"
            break;
        }

        // @ts-ignore
        squareDivs[i].style.background = squareColour
      }
    }

    let averageDiv = modelDiv.querySelector(".model-average")

    let averageTimePassed = 0
    last30GenTimes.forEach(timePassed => {
      averageTimePassed += timeStringToSeconds(timePassed) / last30GenTimes.length
    })

    if(averageTimePassed !== 0) {
      // @ts-ignore
      averageDiv.innerText = "avg. " + secondsToTimeString(averageTimePassed)
    }

    // Iterate & Update rectangle's colors depending on the status of the Jobs
    for(let i = 0; i < squareDivs.length; i++)
    {
      let listOffset = modelJobsList.length - squareDivs.length
      let index = listOffset > 0 ? i + listOffset : i

      if(modelJobsList[index])
      {
        let squareStatus = modelJobsList[index].status
        const localFirstCallTime = new Date(modelJobsList[index].firstCallTime)
        const localLastUpdateTime = new Date(modelJobsList[index].lastUpdateTime)

        if(squareStatus === TestModelSteps.COMPLETED)
        {
          // @ts-ignore
          squareDivs[i].style.background = getAdjustedColor("#62d582", timeStringToSeconds(getTimeDifference(localFirstCallTime, localLastUpdateTime)), averageTimePassed, averageTimePassed * 10, 180)
        }
      }
    }
  }
}

function updateDailyView(){

// Iterate through all Models and update their statuses
  //localStorage.forEach(modelJobsList => {
    //updateDailyModelView(modelJobsList)
  //})
}

function updateDailyModelView(modelJobsList : StatusJob[]){
  const modelDiv = document.getElementById(modelJobsList[0].name)

  // Get all HTML rectangles to update them
  if(modelDiv && modelJobsList[0].name === "Dalle3"){
    let squareDivs = modelDiv.querySelectorAll(".status-square")

    let lastIteratedDate = getDateFromUTCString(modelJobsList[0].firstCallTime)
    let currentIteratedDate = getDateFromUTCString(modelJobsList[0].firstCallTime)
    let j = 0
    let squareColour = "lightgray"

    // Iterate & Update rectangle's colors depending on the status of the Jobs
    for(let i = 0; i < squareDivs.length; i++)
    {
      squareColour = "lightgray"
      let squareStatus = TestModelSteps.NONE
      let hasHadError : boolean = false

      for(j; j < modelJobsList.length; j++){
        currentIteratedDate = getDateFromUTCString(modelJobsList[j].firstCallTime)
        squareStatus = modelJobsList[j].status

        if(!hasHadError)
          squareColour = "#62d582"

        if(squareStatus === TestModelSteps.FAILED || squareStatus === TestModelSteps.TIMEOUT_START || squareStatus === TestModelSteps.TIMEOUT_GENERATING) {
          squareColour = "#cdec49"
          hasHadError = true
        }

        if(lastIteratedDate !== currentIteratedDate){
          lastIteratedDate = currentIteratedDate
          if(modelJobsList[j-1] && modelJobsList[j-1].status === TestModelSteps.FAILED)
            squareColour = "#E84855"
          else if (modelJobsList[j-1] && (modelJobsList[j-1].status === TestModelSteps.TIMEOUT_START || modelJobsList[j-1].status === TestModelSteps.TIMEOUT_GENERATING))
            squareColour = "#FF9B71"
          break;
        }

        if(j === modelJobsList.length - 1)
        {
          if(modelJobsList[j] && modelJobsList[j].status === TestModelSteps.FAILED)
            squareColour = "#E84855"
          else if (modelJobsList[j] && (modelJobsList[j].status === TestModelSteps.TIMEOUT_START || modelJobsList[j-1].status === TestModelSteps.TIMEOUT_GENERATING))
            squareColour = "#FF9B71"
        }
      }

      // @ts-ignore
      squareDivs[i].style.background = squareColour
    }
  }
}


function createModelsView() {
  if(models_view)
  {
    let i : number = 0
    let j : number = 0

    // For every existing model, create the HTML element and its Status-Rectangles
    for (const model in Models) {
      if (isNaN(Number(model))) {
        i++

        if(i === 1 || i - 1 === Math.round(Object.keys(Models).length / 4))
        {
          j++
          const modelColumn = document.createElement("div")
          modelColumn.className = "model-column"
          modelColumn.id = "model-column-" + j
          models_view.appendChild(modelColumn)

          if(j === 1)
            modelColumn.style.alignItems = "flex-end"
          else
            modelColumn.style.alignItems = "flex-start"
        }

        const modelColumnTarget = document.getElementById("model-column-" + j)

        if(!modelColumnTarget)
          return

        // Create the Model Div
        const modelDiv = document.createElement("div");
        modelDiv.className = "model"
        modelDiv.innerText = model
        modelDiv.id = model
        modelColumnTarget.appendChild(modelDiv);
        modelDiv.appendChild(document.createElement("br"));

        // Create Average Time
        const name = document.createElement("div");
        modelDiv.appendChild(name);
        name.innerText = "avg. -"
        name.className = "model-average"

        // Create the Status Rectangles
        for (let i = 0; i < 60; i++) {
          if(i === 30)
            modelDiv.appendChild(document.createElement("br"))

          const squareDiv = document.createElement('div');
          squareDiv.className = 'status-square'
          squareDiv.id = i.toString()
          modelDiv.appendChild(squareDiv)

          //Add to list in order to link tooltip afterwards
          addRealTimeToolTipListeners(squareDiv)
        }
      }
    }
  }
}

function showAdminUI() {
  // Create the toggle auto-test button
  automatic_testing_model_button = document.createElement('button');
  automatic_testing_model_button.id = "auto-test-model-button"
  automatic_testing_model_button.textContent = "Toggle Auto-Testing"
  automatic_testing_model_button.className = "bottom-right-UI"
  buttonsSection.appendChild(automatic_testing_model_button)

  automatic_testing_model_button.addEventListener("click", () => {
      sendHTTPActionToServer("toggleAutoTest", "doIT")
  })

  // Create the random model button
  random_model_button = document.createElement('button')
  random_model_button.id = "random-model-button"
  random_model_button.textContent = "Random Model"
  random_model_button.className = "bottom-right-UI"
  buttonsSection.appendChild(random_model_button)
  random_model_button.addEventListener("click", () => {

    isRandomProgramOn = !isRandomProgramOn

    // Manage On/Off
    if(isRandomProgramOn)
    {
      sendHTTPActionToServer("startRandom", "start")
      random_model_button.textContent = "Stop Testing"
    }
    else
    {
      sendHTTPActionToServer("stopRandom", "stop")
      random_model_button.textContent = "Random Model"
    }
  })

  // Create the specific model button
  specific_model_button = document.createElement('button')
  specific_model_button.id = "specific-model-button"
  specific_model_button.textContent = "Run Selected Model"
  specific_model_button.className = "bottom-right-UI"

  buttonsSection.appendChild(specific_model_button)
  specific_model_button.addEventListener("click", () => {
    isSpecificProgramOn = !isSpecificProgramOn

    // Manage On/Off
    if(isSpecificProgramOn)
    {
      sendHTTPActionToServer('startSpecific', model_dropdown.value)
      specific_model_button.textContent = "Stop Testing"
    }
    else
    {
      sendHTTPActionToServer("stopSpecific", "stop")
      specific_model_button.textContent = "Run Selected Model"
    }
  })

  // Create the models dropdown
  model_dropdown = document.createElement('select')
  model_dropdown.id = "model-dropdown"
  model_dropdown.className = "bottom-right-UI"
  buttonsSection.appendChild(model_dropdown)

  let defaultOption = document.createElement('option');
  defaultOption.value = '';
  defaultOption.disabled = true;
  defaultOption.selected = true;
  defaultOption.textContent = 'Select a model';
  model_dropdown.appendChild(defaultOption);

  // Fill the drodpdown with all model options
  for (const model in Models) {
    if (isNaN(Number(model))) {
      const option = document.createElement("option");
      option.text = model;
      option.value = model;
      model_dropdown.add(option);
    }
  }

  // Delete the input box
  admin_token_input.remove()
}



function computeStatusTooltipPosition(referenceElement : HTMLElement) {
  computePosition(referenceElement, status_tooltip, {
    placement: "top",
    middleware: [
      offset(6),
      flip({padding: 5}),
      shift({padding: 5}),
      arrow({element: status_tooltip_arrow})
    ],
  }).then(({x, y, placement, middlewareData}) => {
    Object.assign(status_tooltip.style, {
      left: `${x}px`,
      top: `${y}px`,
    });

    // Tooltip Pointing Arrow Styling
    // @ts-ignore
    const {x: arrowX, y: arrowY} = middlewareData.arrow;

    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]];

    Object.assign(status_tooltip_arrow.style, {
      left: arrowX != null ? `${arrowX}px` : '',
      top: arrowY != null ? `${arrowY}px` : '',
      right: '',
      bottom: '',
      // @ts-ignore
      [staticSide]: '-4px',
    });
  });
}


function showTooltip(referenceElement : HTMLElement) {
  status_tooltip.style.display = 'block';
  computeStatusTooltipPosition(referenceElement);
}

function hideTooltip() {
  status_tooltip.style.display = '';
}

const eventAndListeners = [
  ['mouseenter', showTooltip],
  ['mouseleave', hideTooltip],
  ['focus', showTooltip],
  ['blur', hideTooltip],
]

function addRealTimeToolTipListeners(element : any) {
  eventAndListeners.forEach(([event, listener]) => {
    if (listener === showTooltip) {
      element.addEventListener(event, () => {
        let modelData : any[] = localStorage.get(element.parentElement.id)

        if(!modelData)
          return;

        let squareDivs = element.parentElement.querySelectorAll(".status-square")

        // To show only the latest Jobs in the list
        let listOffset = modelData.length - squareDivs.length
        let index : number = listOffset > 0 ? (parseInt(element.id)) + listOffset : parseInt(element.id)

        if(modelData[index])
        {
          const localFirstCallTime = new Date(modelData[index].firstCallTime)
          const localLastUpdateTime = new Date(modelData[index].lastUpdateTime)
          let status = "undefined"
          switch(modelData[index].status){
            // Start State
            case 0:
            case 1:
              status = "Starting"
              status_tooltip.innerText =
                  "Status: " + status +
                  "\nGeneration Started: " + localFirstCallTime.toLocaleString() +
                  "\nLast Update: " + localLastUpdateTime.toLocaleString() +
                  "\nTime Passed: " + timeFrameUntilNow(localFirstCallTime)
              break;

            // Generating State
            case 2:
              status = "Generating"
                if(!modelData[index].step)
                  status_tooltip.innerText =
                      "Status: " + status +
                      " (" + modelData[index].progress + "%)" +
                      "\nGeneration Started: " + localFirstCallTime.toLocaleString() +
                      "\nLast Update: " + localLastUpdateTime.toLocaleString() +
                      "\nTime Passed: " + timeFrameUntilNow(localFirstCallTime)
                else
                  status_tooltip.innerText =
                      "Status: " + status +
                      " (" + modelData[index].progress + "% - Step " + modelData[index].step + ")" +
                      "\nGeneration Started: " + localFirstCallTime.toLocaleString() +
                      "\nLast Update: " + localLastUpdateTime.toLocaleString() +
                      "\nTime Passed: " + timeFrameUntilNow(localFirstCallTime)
              break;

            // Completed State
            case 3:
              status = "Completed"
              status_tooltip.innerText =
                  "Status: " + status +
                  "\nGeneration Started: " + localFirstCallTime.toLocaleString() +
                  "\nGeneration Finished: " + localLastUpdateTime.toLocaleString() +
                  "\nTotal Time: " + getTimeDifference(localFirstCallTime, localLastUpdateTime)
              break;

            // Failed State
            case 4:
              status = "Failed"
              status_tooltip.innerText =
                  "Status: " + status +
                  "\nGeneration Started: " + localFirstCallTime.toLocaleString() +
                  "\nLast Update: " + localLastUpdateTime.toLocaleString() +
                  "\nTotal Time: " + getTimeDifference(localFirstCallTime, localLastUpdateTime)
              break;

            // Timeout States
            case 5:
            case 6:
              status = "Failed"
              status_tooltip.innerText =
                  "Status: " + status + " - " + modelData[index].errorMessage +
                  "\nGeneration Started: " + localFirstCallTime.toLocaleString() +
                  "\nLast Update: " + localLastUpdateTime.toLocaleString() +
                  "\nTime Passed: " + timeFrameUntilNow(localFirstCallTime)
              break;
          }
          showTooltip(element)
        }
      });
    } else {
      element.addEventListener(event, listener);
    }
  });
}

function addDailyToolTipListeners(element : any) {
  eventAndListeners.forEach(([event, listener]) => {
    if (listener === showTooltip) {
      element.addEventListener(event, () => {
        let modelData : any[] = localStorage.get(element.parentElement.id)

        if(!modelData)
          return;

        let squareDivs = element.parentElement.querySelectorAll(".status-square")

        // To show only the latest Jobs in the list
        let listOffset = modelData.length - squareDivs.length
        let index : number = listOffset > 0 ? (parseInt(element.id)) + listOffset : parseInt(element.id)

        if(modelData[index])
        {
          const localFirstCallTime = new Date(modelData[index].firstCallTime)
          const localLastUpdateTime = new Date(modelData[index].lastUpdateTime)
          let status = "undefined"
          switch(modelData[index].status){
              // Start State
            case 0:
            case 1:
              status = "Starting"
              status_tooltip.innerText =
                  "Status: " + status +
                  "\nGeneration Started: " + localFirstCallTime.toLocaleString() +
                  "\nLast Update: " + localLastUpdateTime.toLocaleString()
              break;

              // Generating State
            case 2:
              status = "Generating"
              status_tooltip.innerText =
                  "Status: " + status +
                  " (" + modelData[index].progress + "%)" +
                  "\nGeneration Started: " + localFirstCallTime.toLocaleString() +
                  "\nLast Update: " + localLastUpdateTime.toLocaleString() +
                  "\nTime Passed: " + timeFrameUntilNow(localFirstCallTime)
              break;

              // Completed State
            case 3:
              status = "Completed"
              status_tooltip.innerText =
                  "Status: " + status +
                  "\nGeneration Started: " + localFirstCallTime.toLocaleString() +
                  "\nGeneration Finished: " + localLastUpdateTime.toLocaleString() +
                  "\nGeneration Time: " + timeFrameUntilNow(localFirstCallTime)
              break;

              // Failed State
            case 4:
              status = "Failed"
              status_tooltip.innerText =
                  "Status: " + status +
                  "\nGeneration Started: " + localFirstCallTime.toLocaleString() +
                  "\nLast Update: " + localLastUpdateTime.toLocaleString() +
                  "\nGeneration Time: " + timeFrameUntilNow(localFirstCallTime)
              break;

              // Timeout States
            case 5:
            case 6:
              status = "Failed"
              status_tooltip.innerText =
                  "Status: " + status + " - " + modelData[index].errorMessage +
                  "\nGeneration Started: " + localFirstCallTime.toLocaleString() +
                  "\nLast Update: " + localLastUpdateTime.toLocaleString() +
                  "\nTime Passed: " + timeFrameUntilNow(localFirstCallTime)
              break;
          }
          showTooltip(element)
        }
      });
    } else {
      element.addEventListener(event, listener);
    }
  });
}

function timeFrameUntilNow(startDate: Date): string {
  const currentDate = new Date();
  let difference = currentDate.getTime() - startDate.getTime();

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(difference / (1000 * 60 * 60));
  difference -= hours * (1000 * 60 * 60);
  const minutes = Math.floor(difference / (1000 * 60));
  difference -= minutes * (1000 * 60);
  const seconds = Math.floor(difference / 1000);

  // Format the result
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

function getTimeDifference(startDate: Date, endDate: Date): string {
  let difference = endDate.getTime() - startDate.getTime();

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(difference / (1000 * 60 * 60));
  difference -= hours * (1000 * 60 * 60);

  const minutes = Math.floor(difference / (1000 * 60));
  difference -= minutes * (1000 * 60);

  const seconds = Math.floor(difference / 1000);

  // Format the result
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

function timeStringToSeconds(timeString: string): number {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

function secondsToTimeString(totalSeconds: number): string {
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.round(totalSeconds % 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

function cleanRawStorage() {
  // Convert the Name+Date Map into one that simply uses the Name as the key
  rawLocalStorage.forEach(modelJobsList => {
    let currentIteratedModel = modelJobsList[0].name

    let localJobsList : any[] = localStorage.get(currentIteratedModel) || []

    // @ts-ignore
    modelJobsList.forEach(modelJob => {
      // If it is a new job, add it, if not, replace it.
      if(!localJobsList.some(savedJob => savedJob.id === modelJob.id)) {
        localJobsList.push(modelJob)
      } else {
        let replaceIndex = localJobsList.findIndex(savedJob => savedJob.id === modelJob.id)
        localJobsList[replaceIndex] = modelJob
      }
    })

    localStorage.set(currentIteratedModel, localJobsList)
  })
}

function getDateFromUTCString(fullUTCString : string){
  const date = new Date(fullUTCString)

  const year = date.getUTCFullYear()
  const month = date.getUTCMonth() + 1
  const day = date.getUTCDate()

  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

  return formattedDate
}

function hexToRgb(hex : string) {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

function rgbToHex(r : number, g : number, b : number) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

function getAdjustedColor(baseColor : string, value : number, reference  : number, maxDifference : number, darkLimit : number) {
  if (value <= reference) {
    // If the value is less than or equal to the reference, do not modify the color
    return baseColor;
  }

  const { r, g, b } = hexToRgb(baseColor);
  const difference = Math.abs(value - reference);
  const normalizedDifference = Math.min(difference / maxDifference, 1);

  // Set a minimum intensity limit for the dark color
  const minIntensity = darkLimit / 255; // darkLimit is expected to be between 0 and 255

  // Adjust the color intensity by scaling down the RGB values
  const adjustFactor = Math.max(1 - normalizedDifference, minIntensity);
  const newR = Math.round(r * adjustFactor);
  const newG = Math.round(g * adjustFactor);
  const newB = Math.round(b * adjustFactor);

  return rgbToHex(newR, newG, newB);
}