// @ts-ignore

let theImagePrompt : string = "a red cube"
let theFaceImagePrompt : string = "a human"
let theGarmentPrompt : string = "a red dress"
let theTextPrompt : string = "what is the color of a red cube?"

let theImage : string = "https://t3.ftcdn.net/jpg/02/84/71/02/360_F_284710260_VbqMkiKzmtGNHX0FuO7M2TdEXlwMrqmC.jpg"
let theFaceImage : string = "https://i0.wp.com/post.medicalnewstoday.com/wp-content/uploads/sites/3/2020/03/GettyImages-1092658864_hero-1024x575.jpg?w=1155&h=1528"
let theFaceImage2 : string = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Pierre-Person.jpg/1200px-Pierre-Person.jpg"
let theModelImage : string = "https://st.mngbcn.com/rcs/pics/static/T6/fotos/outfit/S20/67027130_05-99999999_01.jpg?ts=1709712201434&imwidth=360&imdensity=2"
let theGarmentImage : string = "https://americantall.com/cdn/shop/products/American-Tall-Women-Sleevless-FittedDress-ContrastTrim-RedWhite-front.jpg?v=1646059817"

let theHex : string = "#FF000"
let theMask : string = "https://png.pngtree.com/png-vector/20240204/ourmid/pngtree-square-photo-in-black-png-image_11609906.png"
let theStyle : string = "3D"

const standardStartingTimeoutTime : number = 600000
const standardGeneratingTimeoutTime : number = 30000

export enum Models {
	//ImageOperations
	ConvertToSVG,
	ExtractColors,
	ExtractFaceMask,
	HexToPantone,

	//OpenAI
	ChatGPTGirbau,
	ChatGPTSingle,
	Dalle3,
	GPT,

	//Replicate - Computer Vision
	Llava,

	//Replicate - ControlNet
	MagicRefiner,
	SDXLControlNet,
	SDXLControlNetLoRA,
	SDXLControlNetLoRAInpaint,

	//Replicate - Face Operations
	BecomeImage,
	FaceRestoration,
	FaceSwap,
	FaceToAnything,
	FaceToMany,
	SDXLStickersControlNet,

	//Replicate - LCM
	FastImagePaint,

	//Replicate - Pipelines (Moodboards)
	Image2MoodboardPipeline,
	Text2MoodboardPipeline,

	//Replicate - Pipelines
	CreativeUpscalePipeline,
	ExtractColorsPipeline,
	FlatToRealPipeline,
	InstructImage2ImagePipeline,
	RefinerPipeline,
	SDXLControlNetRefinerPipeline,
	SDXLMaterialPipeline,
	SDXLRefinerPipeline,
	StitchingPipeline,
	TechnicalDrawingPipeline,
	TestConditionalPipeline,
	TextureTransferPipeline,
	TryOnPipeline,

	//Replicate - Stable Diffusion XL
	SDXL4Step,
	GarmentReverse,
	RemovePattern,
	SDXLOutpaint,
	StableDiffusionXL,
	StickerMakerXL,
	TextureTransfer,

	//Replicate - Upscale
	ClarityUpscale,
	ESRGAN,
	MagicUpscale,

	// Replicate
	Blend,
	RemoveBackground,
	RemoveObject,
	TryOn
}

export enum TestModelSteps {
	NONE,
	STARTING,
	GENERATING,
	COMPLETED,
	FAILED,
	TIMEOUT_START,
	TIMEOUT_GENERATING,
}

export const jobConfigurations = [
	//region ImageOperations
	{
		model: "ConvertToSVG",
		parameters: {
			image: theImage,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "ExtractColors",
		parameters: {
			image: theImage,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "ExtractFaceMask",
		parameters: {
			image: theFaceImage,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "HexToPantone",
		parameters: {
			hex: theHex,
			image: theImage,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	//endregion

	//region OpenAI
	{
		model: "ChatGPTGirbau",
		parameters: {
			prompt: theTextPrompt,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "ChatGPTSingle",
		parameters: {
			prompt: theTextPrompt,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "Dalle3",
		parameters: {
			prompt: theImagePrompt,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "GPT",
		parameters: {
			prompt: theTextPrompt,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	//endregion

	//region Replicate

		//region Computer Vision
	{
		model: "Llava",
		parameters: {
			image: theImage,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
		//endregion

		//region ControlNet
	{
		model: "MagicRefiner",
		parameters: {
			image: theImage,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "SDXLControlNet",
		parameters: {
			prompt: theImagePrompt,
			image: theImage
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "SDXLControlNetLoRA",
		parameters: {
			prompt: theImagePrompt,
			image: theImage
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "SDXLControlNetLoRAInpaint",
		parameters: {
			prompt: theImagePrompt,
			image: theImage,
			mask: theMask
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
		//endregion

		//region Face Operations
	{
		model: "BecomeImage",
		parameters: {
			prompt: theFaceImagePrompt,
			image: theFaceImage,
			image_to_become: theFaceImage2
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "ImageProcessingUtils",
		parameters: {
			model: "FaceRestoration",
			image1: theFaceImage,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
		isRedirected: true
	},
	{
		model: "ImageProcessingUtils",
		parameters: {
			model: "FaceSwap",
			image1: theFaceImage,
			image2: theFaceImage2,
		},
		isRedirected: true
	},
	{
		model: "FaceToAnything",
		parameters: {
			prompt: theImagePrompt,
			image: theFaceImage,
		}
	},
	{
		model: "FaceToMany",
		parameters: {
			prompt: theFaceImagePrompt,
			image: theFaceImage,
			style: theStyle
		}
	},
	{
		model: "SDXLStickersControlNet",
		parameters: {
			prompt: theImagePrompt,
			image: theImage,
			control_image: theImage,
			ip_image: theImage
		}
	},
	//endregion

		//region LCM
	{
		model: "FastImagePaint",
		parameters: {
			prompt: theImagePrompt,
			image: theImage
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
		//endregion

		//region Pipelines
			//region Moodboard
	{
		model: "Image2MoodboardPipeline",
		parameters: {
			image: theImage,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "Text2MoodboardPipeline",
		parameters: {
			prompt: theImagePrompt,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},

			//endregion
	{
		model: "CreativeUpscalePipeline",
		parameters: {
			image: theImage,
			prompt: theImagePrompt
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "ExtractColorsPipeline",
		parameters: {
			image: theImage
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "FlatToRealPipeline",
		parameters: {
			image: theImage
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "InstructImage2ImagePipeline",
		parameters: {
			image: theImage,
			prompt: theImagePrompt
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "RefinerPipeline",
		parameters: {
			image: theImage
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "SDXLControlNetRefinerPipeline",
		parameters: {
			prompt: theImagePrompt,
			image: theImage
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "SDXLMaterialPipeline",
		parameters: {
			prompt: theImagePrompt,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "SDXLRefinerPipeline",
		parameters: {
			prompt: theImagePrompt,
			model: "SDXL",
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "StitchingPipeline",
		parameters: {
			image_base: theImage,
			images_in: [theImage],
			aabb_base: 	[1362.4605997204624, 1618.4605997204624, 4652.0258995806935, 4908.0258995806935],
			aabbs_in: [[1389.05, 1548.6268769132955, 4681.203438456648, 4840.780315369943]]
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "TechnicalDrawingPipeline",
		parameters: {
			prompt: theImagePrompt,
			image: theImage,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "TestConditionalPipeline",
		parameters: {
			prompt: theImagePrompt,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "TextureTransferPipeline",
		parameters: {
			image: theImage,
			texture: theImage,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "TryOnPipeline",
		parameters: {
			garm_img: theGarmentImage,
			human_img: theModelImage,
			garment_des: theGarmentPrompt
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},

		//endregion

		//region StableDiffusionXL
	{
		model: "SDXL4Step",
		parameters: {
			prompt: theImagePrompt,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "GarmentReverse",
		parameters: {
			prompt: theGarmentPrompt,
			image: theGarmentImage
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "RemovePattern",
		parameters: {
			prompt: theGarmentPrompt,
			image: theGarmentImage
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "SDXLOutpaint",
		parameters: {
			image: theModelImage,
			outpaint_direction: "up",
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "StableDiffusionXL",
		parameters: {
			prompt: theImagePrompt,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "StickerMakerXL",
		parameters: {
			prompt: theImagePrompt,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "TextureTransfer",
		parameters: {
			image: theGarmentImage,
			texture: theImage,
			prompt: theGarmentPrompt
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
		//endregion

		//region Upscale
	{
		model: "ClarityUpscale",
		parameters: {
			image: theImage,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "ESRGAN",
		parameters: {
			image: theImage,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "MagicUpscale",
		parameters: {
			image: theImage,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
		//endregion

	{
		model: "Blend",
		parameters: {
			image1: theImage,
			image2: theImage,
			prompt: theImagePrompt
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "RemoveBackground",
		parameters: {
			image: theImage,
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "RemoveObject",
		parameters: {
			image: theImage,
			mask: theMask
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},
	{
		model: "TryOn",
		parameters: {
			garm_img: theGarmentImage,
			garment_des: "dress",
			human_img: theModelImage
		},
		startingTimeoutTime: standardStartingTimeoutTime,
		generatingTimeoutTime: standardGeneratingTimeoutTime,
	},

	//endregion
];

